import React from 'react';
import PropTypes from 'prop-types';
import FeatureSlide from '../singletonBlocks/FeatureSlide';
import '../css/slick.css';
import Slider from 'react-slick';

class FeatureSlider extends React.Component {
  constructor({props}) {
    super(props);
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 800,
      autoplaySpeed: 8000,
      arrows: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            dots: true,
            arrows: false
          }
        }
      ]
    };
    return (
      <Slider {...settings} className="feature-slider relative">
        {this.props.input.items.map(slide => (
          <FeatureSlide input={slide} />
        ))}
      </Slider>
    );
  }
}

export default FeatureSlider;

FeatureSlider.propTypes = {
  input: PropTypes.object.isRequired
};
