import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import FeaturedService from '../components/FeaturedService';
import ServiceCard from '../components/ServiceCard';
import ServiceSlider from '../components/ServiceSlider';
import FeatureSlider from '../singletonBlocks/FeatureSlider';
import FeatureQuote from '../singletonBlocks/FeatureQuote';
import TestimonialBlock from '../singletonBlocks/TestimonialBlock';
import SEO from '../components/seo';

const IndexPage = ({data: {prismicHomePage, services}}) => {
  const {data} = prismicHomePage;
  const {edges} = services;
  const FirstService = edges[0];
  const LastServices = edges.slice(3);

  return (
    <Layout headerColor="blue">
      <SEO title="Welcome" keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']} />
      <div className="header-spacer" />
      <FeatureSlider input={data.body[0]} />
      <FeatureQuote input={data} />
      <h2 className="blue f2 f1-l center tc mt6 mb6">Our Solutions</h2>
      <FeaturedService input={FirstService.node} />
      <div className="pl5-l pr5-l flex-l flex-wrap mb5-l mb3">
        {edges.slice(1, 3).map(serviceItem => (
          <ServiceCard input={serviceItem.node} classNames="pa3 w-100 w-50-l pa4-l" />
        ))}
      </div>
      <ServiceSlider input={LastServices} />
      <TestimonialBlock input={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicHomePage {
      data {
        page_title {
          text
        }
        quote {
          text
        }
        quote_link {
          url
        }
        testimonial {
          text
        }
        testimonial_author {
          text
        }
        body {
          ... on PrismicHomePageBodyFeatureSlideshow {
            items {
              title {
                text
              }
              text {
                html
              }
              link_text {
                text
              }
              link {
                slug
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    services: allPrismicService(sort: {fields: [data___service_weight], order: DESC}) {
      edges {
        node {
          uid
          data {
            service_name {
              text
            }
            portal_text {
              text
            }
            service_weight
            portal_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
