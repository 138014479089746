import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {Link} from 'gatsby';
const FeatureSlide = ({input}) => (
  <div className="pl5-l pr0-l flex-l flex-row-reverse-l items-center bottom-border-on relative">
    <div className="feat-img obj-fit flex w-50-l overflow-hidden">
      <Img fluid={input.image.localFile.childImageSharp.fluid} className="w-100 h-auto" />
    </div>
    <div className="pl3 pr3 w-100 w-50-l pl0-l pr6-l measure-wide center">
      <h2 className="blue f2 f-headline-l mt0-l">{input.title.text}</h2>
      <div>
        <div dangerouslySetInnerHTML={{__html: input.text.html}} className="f4 lh-copy" />
      </div>
      <Link to={`/${input.link.slug}`} className="button mt3">
        {input.link_text.text}
      </Link>
    </div>
  </div>
);

export default FeatureSlide;

FeatureSlide.propTypes = {
  input: PropTypes.object.isRequired
};
