import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
const FeatureQuote = ({input}) => (
  <div className="pt6 pb6 pl4 pr4 tc relative bg-blue mt5 mt0-l">
    <div dangerouslySetInnerHTML={{__html: input.quote.text}} className="f1-l f2 lh-copy measure-wide center" />
    <Link to={input.quote_link.url} className="blue ttu link f5 bold tracked mt4 dib pb4">
      About DBR
    </Link>
  </div>
);

export default FeatureQuote;

FeatureQuote.propTypes = {
  input: PropTypes.object.isRequired
};
