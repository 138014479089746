import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {Link} from 'gatsby';

const FeaturedService = ({input}) => (
  <div className="pl3 pr3 pl0-l pr5-l flex-l items-center mb6-l mb3">
    <Link to={input.uid} className="w-100 w-50-l">
      <Img fluid={input.data.portal_image.localFile.childImageSharp.fluid} className="w-100 h-auto" />
    </Link>
    <div className="w-100 w-50-l pl5-l pr5-l">
      <div className="f3 blue medium lh-copy mt4 mb2">{input.data.service_name.text}</div>
      <div className="lh-copy f4 measure">{input.data.portal_text.text}</div>
      <Link to={input.uid} className="button mt4">
        Read More
      </Link>
    </div>
  </div>
);
export default FeaturedService;

FeaturedService.propTypes = {
  input: PropTypes.object.isRequired
};
