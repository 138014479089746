import React from 'react';
import PropTypes from 'prop-types';

const TestimonialBlock = ({input}) => (
  <div className="pt6 pb6 pl4 pr4 tc relative mt5 mt0-l">
    <div dangerouslySetInnerHTML={{__html: input.testimonial.text}} className="f3 medium lh-copy center measure-wide" />
    <div className="blue bold dib mt4">{input.testimonial_author.text}</div>
  </div>
);

export default TestimonialBlock;

TestimonialBlock.propTypes = {
  input: PropTypes.object.isRequired
};
